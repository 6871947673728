import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Menu from '../components/menu';
import Footer from '../components/footer';
import Counter from '../components/counter';
import { AppContext, App } from '../components/app';
import useAxios from 'axios-hooks';
import axios from 'axios';
import { navigate } from 'gatsby';

const IndexPage = ({ location }) => {
    const [webmasterId, setWebmasterId] = useState('');
    const [isError, showError] = useState(false);

    const context = useContext(AppContext);
    const { state, dispatch } = context;

    const submitForm = async e => {
        e.preventDefault();
        showError(false);
        const id = webmasterId.trim();
        if (id !== '') {
            const response = await axios({
                url:
                    'https://s88v5aj97b.execute-api.eu-west-1.amazonaws.com/production/about.html',
                params: {
                    loginsent: 1,
                    username: id,
                    json: 1,
                },
            });
            if (response.data) {
                if (response.data.result === 0) {
                    showError(true);
                } else if (response.data.result === 1) {
                    dispatch({ type: 'login', affiliateId: id });
                    navigate('/tools-fhg');
                }
            }
        }
    };

    return (
        <Layout>
            <Menu location={location} />
            {state && state.affiliateId ? (
                <>
                    <h1 className="huge padding-bottom-0">Welcome</h1>
                    <p className="hero">W4B affiliate program</p>
                    <p className="small padding-bottom-0">
                        We pay you 50% and more of&nbsp;the earnings from
                        every&nbsp;new&nbsp;signup, including recurring sales,
                        that you send to&nbsp;
                        <a
                            href="https://www.watch4beauty.com"
                            className="underline"
                            title="Watch4beauty"
                        >
                            Watch4beauty
                        </a>
                        . Refer another great webmasters to our affiliate
                        program and&nbsp;make 10% of all their sales.
                    </p>
                    <div className="spacer spacer-80">&nbsp;</div>
                </>
            ) : (
                <>
                    <h1 className="huge padding-bottom-0">Welcome</h1>
                    <p className="hero padding-bottom-32">
                        W4B affiliate program
                    </p>
                    <form onSubmit={submitForm}>
                        <div className="forms">
                            <div className="forms-fix newsletter">
                                <div className="noborder padding-bottom-0">
                                    {isError && (
                                        <div className="alert">
                                            <h4>Invalid affiliate ID</h4>
                                        </div>
                                    )}
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            id="news-field"
                                            value={webmasterId}
                                            onChange={e =>
                                                setWebmasterId(e.target.value)
                                            }
                                            className={
                                                webmasterId.trim() !== ''
                                                    ? 'has-value'
                                                    : ''
                                            }
                                        />
                                        <label
                                            className="control-label"
                                            htmlFor="news-field"
                                        >
                                            Enter your affiliate ID
                                        </label>
                                    </div>
                                    <div className="button-container">
                                        <button
                                            className="button full"
                                            type="submit"
                                        >
                                            <span>Login</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="spacer spacer-32">&nbsp;</div>
                            </div>
                        </div>
                    </form>
                    <p className="small">
                        We pay you 50% and more of&nbsp;the earnings from
                        every&nbsp;new&nbsp;signup, including recurring sales,
                        that you send to&nbsp;
                        <a
                            href="https://www.watch4beauty.com"
                            className="underline"
                            title="Watch4beauty"
                        >
                            Watch4beauty
                        </a>
                        . Refer another great webmasters to our affiliate
                        program and&nbsp;make 10% of all their sales.
                    </p>
                    <p className="link">
                        <a
                            href="https://nats.bcash4you.com/external.php?page=signup"
                            title="Signup now"
                        >
                            Signup now&nbsp;&nbsp;
                            <svg>
                                <use
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    xlinkHref="#link-next"
                                ></use>
                            </svg>
                        </a>
                    </p>
                    <div className="spacer spacer-80">&nbsp;</div>
                </>
            )}

            <img
                src={require('../images/banners-001.jpg')}
                style={{ width: '100%', height: 'auto' }}
                alt="W4B"
            />
            <div className="spacer spacer-80">&nbsp;</div>
            <h1 className="huge nohyphens">Make money</h1>
            <p>
                You'll get premium erotic content, free&nbsp;hosted photo
                &amp;&nbsp;video galleries, amazing banners, weekly newsletter
                and much more. Weekly payouts are powered by CCBill. For more
                info please feel free to&nbsp;contact us at&nbsp;anytime.
            </p>
            <p className="link">
                <a
                    href="mailto:&#x73;&#x75;&#x70;&#x70;&#x6F;&#x72;&#x74;&#x40;&#x62;&#x63;&#x61;&#x73;&#x68;&#x34;&#x79;&#x6F;&#x75;&#x2E;&#x63;&#x6F;&#x6D;"
                    title="Contact us"
                >
                    Contact us&nbsp;&nbsp;
                    <svg>
                        <use
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            xlinkHref="#link-next"
                        ></use>
                    </svg>
                </a>
            </p>
            <div className="spacer spacer-80">&nbsp;</div>
            <Footer />
        </Layout>
    );
};

export default IndexPage;
